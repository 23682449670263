import ResModel, { RESULT } from "../models/response";

import axios, { AxiosError } from "axios";
import { CookieKey, getAuthData, getRefresh, getSession, login, SessionKey, setAccessTokens, setAuthData } from "./authRepo";
import Auth from "../models/auth";
import ResponseList from "../models/responseList";
import { toJsonObject } from "../utils/utils";



var index = 1;

const  enum API {
  AUTH = '/authorize/coupon-point',
  COMPANY = '/companies',
  ADMIN = '/admin-user',
  MEMBERCARD = '/member-cards',
  COUPONGROUP = '/coupon-groups',
  COUPON = '/coupons',
  POINTRESERVERATE = '/point-info',
  POINT = '/point-records',

  CSMS_STATION = "/stations",
  CSMS_ACCESS_TOKEN = "/auth/api-key",
  CSMS_USER = '/users',

  // TODO API 추가 1. API 주소 등록하고
  MEMBERSHIP_CREATE = "/grade/create",
  MEMBERSHIP_LIST = "/grade/list",
  MEMBERSHIP_DELETE = "/grade/del/",
  MEMBERSHIP_IMMD_APPLY = "/grade/apply/",
  MEMBERSHIP_DETAIL = "/grade/read/",
  MEMBERSHIP_DELETE_GROUP_LIST = '/groups/filter',
  MEMBERSHIP_UPDATE = "/grade/update/",

  MEMBERSHIP_GROUP_DETAIL = '/groups/',
  MEMBERSHIP_GROUP_DUPLICATE_MEMBER_CHECK = '/groups/duplicate',
  MEMBERSHIP_DELETED_GROUP_DETAIL = '/groups/load/',
  MEMBERSHIP_GROUP_CREATE = '/groups',


}

async function getResponseList(api: API, path: string | null = '', query: Object | null = null, converter: Function) {
  const responseListConverter = (json: JSON) => {
    return ResponseList.fromJson(json, converter);
  };
  return await httpGet(api, path, query, responseListConverter);
}

async function getList(api: API, path: string | null = '', query: Object | null = null, converter: Function) {
  const listConverter = (source: Array<any>) => {
    var returnData: any[] = [];
    source.forEach((value) => {
      returnData.push(converter(value));
    });
    return returnData;
  };
  return await httpGet(api, path, query, listConverter);
}

async function getExcellResponse(log: Function,
  method: string,
  url: string
): Promise<ResModel> {
  var count: number = log();
  try {
    var result = await axios.get(
      url,
      {
        method: method,
        headers: getHeader(false, true),
        responseType: 'arraybuffer'
      }
    );
    if (process.env.REACT_APP_MODE === 'DEV') {
      var spendTimeStamp: string = new Date().toLocaleTimeString();
      // console.log(`[${count}] : [res] ${spendTimeStamp} - excell downLoad`);
    }
    const decoder = new TextDecoder('utf8');
    const jsonData = toJsonObject(decoder.decode(result.data));
    if (jsonData.toString().includes('FAIL')) {
      return new ResModel(RESULT.FAIL, '엑셀 다운로드 실패.', null);
    }
    return new ResModel(RESULT.OK, '', result.data);
  } catch (error: unknown | Error | AxiosError) {
    if (process.env.REACT_APP_MODE === 'DEV') {
      var spendTimeStampError: string = new Date().toLocaleTimeString();
      // console.log(`[${count}] : [error] ${spendTimeStampError} - ${error}`);
      index++;
    }
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError;
      if (err.response && err.response?.status === 401) {
        //unauth - refresh
        var refreshResult = await refresh();
        if (refreshResult) {
          return await getExcellResponse(log, method, url);
        } else {
          return ResModel.error('처리 실패');
        }
      }
      var errString: string = '';
      if (err.response) {
        const responseData: any = err.response.data;
        errString = responseData['msg'] as string;
      } else {
        errString = '처리 실패';
      }
      if (process.env.REACT_APP_MODE === 'DEV') {
        console.log(errString);
      }
      return ResModel.error(errString);
    } else {
      return ResModel.error(error ? `${error}` : '처리 실패');
    }
  }

}

async function httpGet(api: API, path: string | null = '', query: Object | null = null, converter: Function | null) {
  var method: string = 'get';
  var url: string = getUrl(api, path, query);
  if (url.includes('/export')) {
    // excel download. method is always get
    return getExcellResponse(
      () => {
        var currentTimeStamp: string = new Date().toLocaleTimeString();
        if (process.env.REACT_APP_MODE === 'DEV') {
          // console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url}`);
        }
        return index;
      },
      method,
      url);
  }
  return request(
    function () {
      var currentTimeStamp: string = new Date().toLocaleTimeString();
      if (process.env.REACT_APP_MODE === 'DEV') {
        // console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url}`);
      }
      return index;
    },
    method,
    url,
    converter
  );
};

async function httpPost(api: API, path: string = '', query: Object | null = null, data: Object | null = null, converter: Function | null) {
  var method: string = 'post';
  var url: string = getUrl(api, path, query);

  return request(
    function () {
      var currentTimeStamp: string = new Date().toLocaleTimeString();
      if (process.env.REACT_APP_MODE === 'DEV') {
        // console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url} : data = ${JSON.stringify(data)}`);
      }
      return index;
    },

    method,
    url,
    converter,
    data);
};

async function httpPut(api: API, path: string = '', query: Object | null = null, data: Object | null = null, converter: Function | null) {
  var method: string = 'put';
  var url: string = getUrl(api, path, query);

  return request(
    function () {
      var currentTimeStamp: string = new Date().toLocaleTimeString();
      if (process.env.REACT_APP_MODE === 'DEV') {
        // console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url} : data = ${JSON.stringify(data)}`);
      }
      return index;
    },

    method,
    url,
    converter,
    data
  );

};

async function httpDelete(api: API, path: string = '', query: Object | null = null, converter: Function | null) {
  var method: string = 'delete';
  var url: string = getUrl(api, path, query);

  return request(
    function () {
      var currentTimeStamp: string = new Date().toLocaleTimeString();
      if (process.env.REACT_APP_MODE === 'DEV') {
        // console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url}`);
      }
      return index;
    },

    method,
    url,
    converter);
};




async function request(
  log: Function,
  method: string,
  url: string,
  converter: Function | null,
  data?: Object | null): Promise<ResModel> {
  //debug
  var req;
  switch (method) {
    case 'get':
      req = axios.get(
        url,
        {
          method: method,
          headers: getHeader(false, false, url),
        }
      );
      break;
    case 'post':
      req = axios.post(
        url,
        data,
        {
          method: method,
          headers: getHeader(false, false, url),
        }
      );
      break;
    case 'put':
      req = axios.put(
        url,
        data,
        {
          method: method,
          headers: getHeader(false, false, url),
        }
      );
      break;
    case 'delete':
      req = axios.delete(
        url,
        {
          method: method,
          headers: getHeader(false, false, url),
        }
      );
      break;
    default:
      break;
  }
  if (req === undefined) {
    return ResModel.error('처리 실패');
  }
  var count: number = log();
  var result;
  try {
    result = await req;
    var responseData = JSON.parse(JSON.stringify(result) ?? '');
    var json = JSON.parse(JSON.stringify(responseData.data) ?? '');
    //debug
    if (process.env.REACT_APP_MODE === 'DEV') {
      var spendTimeStamp: string = new Date().toLocaleTimeString();
      // console.log(`[${count}] : [res] ${spendTimeStamp} - ${JSON.stringify(json)}`);
    }
    index++;
    return ResModel.fromJson(json, converter);
  } catch (error: unknown | Error | AxiosError) {
    if (process.env.REACT_APP_MODE === 'DEV') {
      var spendTimeStampError: string = new Date().toLocaleTimeString();
      // console.log(`[${count}] : [error] ${spendTimeStampError} - ${error}`);
      index++;
    }
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError;
      if (err.response && err.response?.status === 401) {
        //unauth - refresh
        var refreshResult

        // TODO API 추가 2. 아래에 || 추가
        if (url.includes(API.CSMS_STATION)
          || url.includes(API.CSMS_ACCESS_TOKEN)
          || url.includes(API.CSMS_USER)
          || url.includes(API.MEMBERSHIP_CREATE)
          || url.includes(API.MEMBERSHIP_DELETE)
          || url.includes(API.MEMBERSHIP_DETAIL)
          || url.includes(API.MEMBERSHIP_LIST)
          || url.includes(API.MEMBERSHIP_IMMD_APPLY)
          || url.includes(API.MEMBERSHIP_GROUP_DETAIL)
          || url.includes(API.MEMBERSHIP_GROUP_DUPLICATE_MEMBER_CHECK)
          || url.includes(API.MEMBERSHIP_DELETED_GROUP_DETAIL)
          || url.includes(API.MEMBERSHIP_UPDATE)
          || url.includes(API.MEMBERSHIP_GROUP_CREATE)
          || url.includes(API.MEMBERSHIP_DELETE_GROUP_LIST)
        ) {
          refreshResult = await refresh("OTHER");
        } else {
          refreshResult = await refresh();
        }
        if (refreshResult) {
          if (data) {
            return await request(log, method, url, converter, data);
          } else {
            return await request(log, method, url, converter);
          }
        } else {
          return ResModel.error('처리 실패');
        }
      }
      var errString: string = '';
      var errData: any = null;
      if (err.response) {
        const responseData: any = err.response.data;

        errString = responseData['msg'] as string;
        errData = responseData['data']
      } else {
        errString = '처리 실패';
      }
      if (process.env.REACT_APP_MODE === 'DEV') {
        console.log(errString);
      }
      return ResModel.error(errString, errData);
    } else {
      return ResModel.error(error ? `${error}` : '처리 실패');
    }
  }



}

async function refresh(server: string = 'COUPON'): Promise<boolean> {
  const method: string = 'get';
  var url: string = getUrl(API.AUTH, '/refresh', null);
  if (server != "COUPON") {
    try {
      await setAccessTokens()
      return true
    } catch {
      return false
    }
  }
  var config = {
    method: method,
    headers: getHeader(true)
  };

  var currentTimeStamp: string = new Date().toLocaleTimeString();
  // if (process.env.REACT_APP_MODE === 'DEV') {
  //     console.log(`[${index}] : [${method}] ${currentTimeStamp} - ${url}`);
  // }


  try {
    const result = await axios.get(url, config);

    var responseData = JSON.parse(JSON.stringify(result) ?? '');
    var json = JSON.parse(JSON.stringify(responseData.data) ?? '');

    //debug
    var spendTimeStamp: string = new Date().toLocaleTimeString();
    // if (process.env.REACT_APP_MODE === 'DEV') {
    //     console.log(`[${index}] : [res] ${spendTimeStamp} - ${JSON.stringify(json)}`);
    // }
    index++;
    const refreshResult = ResModel.fromJson(json, (json: JSON) => Auth.fromJson(json));
    const authData = refreshResult.data as Auth;
    const preivousAuth = getAuthData();
    authData.login_id = preivousAuth.login_id;
    authData.password = preivousAuth.password;
    if (server == "COUPON") {
      authData.access_token = json.data;
    }
    await setAuthData(authData);
    return true;
  } catch (error: unknown | Error | AxiosError) {
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError;
      if (err.response && err.response?.status === 401) {
        //unauth - relogin
        const reLoginResult = await reLogin();
        return reLoginResult;
      } else {
        return false;
      }
    }
    return false;
  }
}

async function reLogin(): Promise<boolean> {
  try {
    const auth = getAuthData();
    var result = await login(auth.login_id!, auth.password!);
    if (result.result === RESULT.FAIL) {
      return false;
    } else {
      const authData = result.data as Auth;
      auth.access_token = authData.access_token;
      auth.refresh_token = authData.refresh_token;
      await setAccessTokens();
      await setAuthData(auth);
      return true;
    }
  } catch (error) {
    return false;
  }

}
const version: string = '/api/v2';

function getHeader(isRefresh: boolean = false, isExcel: boolean = false, url: string | null = null, isFile?: boolean) {
  var server = "COUPON"

  if (url != null) {
    if (
      url.includes(API.CSMS_STATION) ||
      url.includes(API.CSMS_USER) ||
      url.includes(API.CSMS_ACCESS_TOKEN)
    ) {
      server = "CSMS"
    } else if (
      url.includes(API.MEMBERSHIP_CREATE) ||
      url.includes(API.MEMBERSHIP_DELETE) ||
      url.includes(API.MEMBERSHIP_DETAIL) ||
      url.includes(API.MEMBERSHIP_UPDATE) ||
      url.includes(API.MEMBERSHIP_DELETE_GROUP_LIST) ||
      url.includes(API.MEMBERSHIP_LIST)
    ) {
      server = "MEMBERSHIP"
    }
  }
  type AxiosRequestHeaders = {
    [x: string]: string | number | boolean;
  }
  let headers: AxiosRequestHeaders = {
  };
  headers.Accept = 'application/json';
  headers["Content-Type"] = isExcel ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' : 'application/json; charset=utf-8';

  if (url?.includes(API.MEMBERSHIP_CREATE)) {
    headers["Content-Type"] = 'multipart/form-data; charset=utf-8';
  }

  if (isRefresh) {
    if (getRefresh(CookieKey.Refresh) != null) {
      headers.Authorization = `Bearer ${getRefresh(CookieKey.Refresh)}`;
    }
  } else if (getSession(SessionKey.Access) != null) {
    if (server == 'COUPON') {
      headers.Authorization = `Bearer ${getSession(SessionKey.Access)}`;
    } else if (server == 'CSMS') {
      headers.Authorization = `Bearer ${getSession(SessionKey.CSMSAccess)}`;
    }
  }
  return headers;
}

function getUrl(api: API, path: string | null = '', query: Object | null = null) {
  var queryString: string | null = getQueryString(query) ?? '';
  var host = process.env.REACT_APP_API_HOST;
  var url: string;
  // TODO API 추가 3. 아래 리스트에 서버 마춰서 리스트 안에 추가
  if ([
    API.CSMS_STATION,
    API.CSMS_USER,
    API.CSMS_ACCESS_TOKEN].includes(api)) {
    host = process.env.REACT_APP_CSMS_API_HOST;
    url = `${host}${version}${api}${path}${queryString}`;
    return url;
  }
  if ([
    API.MEMBERSHIP_CREATE,
    API.MEMBERSHIP_UPDATE,
    API.MEMBERSHIP_DELETE_GROUP_LIST,
    API.MEMBERSHIP_DELETE,
    API.MEMBERSHIP_DETAIL,
    API.MEMBERSHIP_LIST,
    API.MEMBERSHIP_IMMD_APPLY,
    API.MEMBERSHIP_GROUP_CREATE,
    API.MEMBERSHIP_DELETED_GROUP_DETAIL,
    API.MEMBERSHIP_GROUP_DUPLICATE_MEMBER_CHECK,
    API.MEMBERSHIP_GROUP_DETAIL].includes(api)) {
    host = process.env.REACT_APP_MEMBERSHIP_API_HOST;
    url = `${host}${version}${api}${path}${queryString}`;
    return url;
  }
  // 그 외 api
  if (API.AUTH.includes(api)) {
    url = `${host}${api}${path}${queryString}`;
    return url;
  }
  url = `${host}/coupon-point${version}${api}${path}${queryString}`;
  return url;

}

function buildParams(searchData: { [key: string]: any }) {
  const params: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(searchData)) {
    if (value !== '') {  // 빈 문자열이 아닌 경우만 추가
      params[key] = value;
    }
  }
  return params;
}

function getQueryString(query: Object | null) {
  if (query == null) {
    return null;
  } else {
    if (Object.keys(query).length === 0) {
      return '';
    } else {
      var tempQueryList = new Array<string>();
      for (const [key, value] of Object.entries(query)) {
        if (value || value == 0) {
          tempQueryList.push(`${key}=${value}`);
        }
      }
      return `?${tempQueryList.join('&')}`;
    }
  }
}

export { httpGet, httpPost, httpPut, httpDelete, getList, getResponseList, API, getQueryString, buildParams };